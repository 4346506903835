<!--业务部费用池-->
<template>
  <div>
    <div class="searchBox" :style="{ opacity: config.isConfigMode ? 0.5 : 1, pointerEvents: config.isConfigMode ? 'none' : 'auto' }">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号" clearable></el-input>
        </el-form-item>
        <el-form-item label="对接人" prop="saleIdList">
          <el-select class="item-choose"
                     v-model="dataForm.saleIdList"
                     size="small"
                     filterable clearable placeholder="请选择"
          >
            <el-option
              v-for="item in userJobMap.SALE_ADMIN"
              :key="item.id"
              :label="item.realName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select class="item-choose" v-model="dataForm.status" style="width: 100%;" filterable clearable placeholder="请选择">
            <el-option label="已审核" value="1"></el-option>
            <el-option label="未审核" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable placeholder="请选择">
            <el-option label="已付款" value="1"></el-option>
            <el-option label="未付款" value="2"></el-option>
            <el-option label="部分付款" value="3"></el-option>
            <el-option label="申请中" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" v-if="newAuth('business:department:export')" @click="downLoad">导出</el-button>
        </el-form-item>
        <el-form-item hidden="hidden">
          <el-button @click="getLatestData()" type="primary">获取最新数据</el-button>
          <el-button size="small" type="success" v-if="newAuth('business:department:update')" @click="openWriteOffPopup(1)" :disabled="!selectFeeData.length">已付款核销</el-button>
          <el-button size="small" type="success" v-if="newAuth('business:department:update')" @click="batchAudit(2)" :disabled="!selectFeeData.length">反核销</el-button>
        </el-form-item>
        <el-form-item style="text-align: right; display: flex; justify-content: flex-end; align-items: center;">
          <div class="operationList">
            <el-button size="small" @click="operatopnList('pass', '', '', 1)"
                       type="primary"
                       v-if="newAuth('business:department:auditStatus1')"
                       :disabled="selectFeeData.length ? false : true">费用审核通过</el-button>
            <el-button size="small" @click="operatopnList('pass', '', '', 2)"
                       type="primary"
                       v-if="newAuth('business:department:auditStatus2')"
                       :disabled="selectFeeData.length ? false : true">反审核</el-button>
            <el-button size="small" type="success" v-if="newAuth('request:order:add')" @click="createPayRequest"
                       :disabled="!selectFeeData.length">
              生成付款申请单
            </el-button>
            <el-button size="small" @click="templateConfigurationMode(0)" type="warning">模板配置模式</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox" :style="{ position: 'relative' }">
      <!-- 配置模式 -->
      <el-row v-if="config.isConfigMode" style="justify-self: center;padding: 10px 10px">
        <el-button size="mini" @click="config.isConfigMode = false" type="danger">取消配置模式</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(1)" type="success">保存模板</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(2)" type="warning">修改模板</el-button>
        <el-button v-no-more-click size="mini" @click="$refs.configTableRef.init(config.configTableId)" type="warning">所有模板</el-button>
        <el-popover placement="right" width="520" trigger="click">
          <div style="justify-self: center;">
            <el-button v-no-more-click @click="checkAllNodes(true)" size="mini">全选</el-button>
            <el-button v-no-more-click @click="checkAllNodes(false)" size="mini">全不选</el-button>
            <el-tooltip class="item" effect="dark" content="可拖拽必须先取消固定才允许拖拽排列,如果有固定固定按钮会显示为绿色" placement="top-start">
              <i class="el-icon-view el-icon--right" />
            </el-tooltip>
          </div>
          <br/>
          <el-tree :data="config.configDefaultColumn"
                   style="max-height: 200px;overflow-y: auto;"
                   default-expand-all
                   show-checkbox
                   draggable
                   node-key="prop"
                   ref="configTableListTree"
                   @check-change="handleCheckChange"
                   @node-drop="handleDrop"
                   :allow-drag="allowDrag"
                   :allow-drop="allowDrop">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <el-row :gutter="20">
                       <el-col :span="16">
                         <div class="grid-content bg-purple">
                           <el-input size="mini" placeholder="列头名" v-model="data.label"></el-input>
                         </div>
                       </el-col>
                       <el-col :span="8">
                         <div class="grid-content bg-purple">
                           <el-link :type="data.fixed && data.fixed === 'left' ? 'success' : 'info'" @click="data.fixed = 'left'" :underline="false" >固定左侧</el-link>
                           <el-link :type="data.fixed && data.fixed === 'right' ? 'success' : 'info'" @click="data.fixed = 'right'" :underline="false" >固定右侧</el-link>
                           <el-link :disabled="!data.fixed" :underline="false" @click="data.fixed = undefined" >取消固定</el-link>
                         </div>
                       </el-col>
                    </el-row>
                  </span>
          </el-tree>
          <el-button style="margin-left: 10px;" size="mini" slot="reference" icon="el-icon-s-tools" circle />
        </el-popover>
        <br/>
        <br/>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-input v-model="config.configModeForm.name" size="mini" placeholder="模板名称" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <el-input v-model="config.configModeForm.remark" size="mini" placeholder="备注" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-switch
                  v-model="config.configModeForm.defaultOrNot"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="'1'"
                  :inactive-value="'0'">
              </el-switch>
            </div>
          </el-col>
        </el-row>
      </el-row>


      <el-table :data="dataList" border size="small" :height="tableHeight" ref="tableRef"
                :span-method="arraySpanMethod"
                :summary-method="getSummaries"
                show-summary
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                v-loading="dataListLoading" style="width: 100%;"
                @selection-change="handleSelectionChange"
                :cell-class-name="getCellClassName">
        <el-table-column type="selection" width="35" fixed="left" align="center"></el-table-column>
        <el-table-column
            v-for="(item, index) in config.configDefaultColumn"
            :key="index"
            align="left"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :fixed="item.fixed"
            :show-overflow-tooltip="true"
            v-if="item.check"
            :class="item.styleClass"
            :class-name="item.styleClass">
          <template slot-scope="scope">
            <!-- 订单状态 -->
            <template v-if="item.prop == 'status'">
              <el-tag v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">未提交</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">待审核</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">调度中</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small">已调度</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="success">已完成</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">异常结束</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">审核拒绝</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">订单退回</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info">已取消</el-tag>
              <el-tag v-else-if="scope.row.status == 4" size="small">待出车</el-tag>
              <el-tag v-else-if="scope.row.status == 5" size="small" type="success">已出车</el-tag>
              <el-tag v-else-if="scope.row.status == 6" size="small" type="success">已到达</el-tag>
              <el-tag v-else-if="scope.row.status == 7" size="small" type="success">已装货</el-tag>
              <el-tag v-else-if="scope.row.status == 15" size="small" type="success">卸货到场</el-tag>
            </template>
            <!-- 审核状态 -->
            <template v-else-if="item.prop == '审核状态'">
              <el-tag v-if="scope.row.officeCostSale.status == 1" size="small" type="success">已审核</el-tag>
              <el-tag v-else size="small" type="danger">未审核</el-tag>
            </template>
            <!-- 付款状态 -->
            <template v-else-if="item.prop == '付款状态'">
              <el-tag v-if="scope.row.officeCostSale.writeStatus == 1" size="small" type="success">已付款</el-tag>
              <el-tag v-if="scope.row.officeCostSale.writeStatus == 2" size="small" type="danger">未付款</el-tag>
              <el-tag v-if="scope.row.officeCostSale.writeStatus == 3" size="small" type="info">部分付款</el-tag>
              <el-tag v-if="scope.row.officeCostSale.writeStatus == 4" size="small" type="warning">申请中</el-tag>
            </template>
            <template v-else>
              {{ getFormatter(item.formatter)(scope.row, item, _.get(scope.row, item.prop)) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('business:department:find')" type="text" size="small" @click="operatopnList('cost', 'view', scope.row)">详情</el-button>
              <!-- 未付款的情况下可以修改数据 -->
              <el-button v-if="newAuth('business:department:update')"
                         type="text" size="small" @click="operatopnList('cost', 'edit', scope.row)">费用修改
              </el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>


    <!-- 弹窗 -->
    <cost-modification ref="cost" @refreshDataList="getDataList"></cost-modification>
    <view-image ref="viewPhoto"></view-image>
    <!-- 核销弹框 -->
    <write-off-popup ref="writeOff" @refreshDataList="getDataList"></write-off-popup>
    <!-- 付款申请单   -->
    <pay-request v-if="requestVisible" ref="payRequest" @refreshDataList="getDataList"></pay-request>


    <!-- 模板配置弹窗 -->
    <configTable ref="configTableRef" />
  </div>
</template>

<script>
import configTable from '@/components/table'
import costModification from './detail/sale-admin-costModification-popup'
import viewImage from '@/views/commonPop/viewImage'
import writeOffPopup from './detail/write-royalty-popup'
import PayRequest from './detail/pay-request-popup'
import qs from 'qs'
import * as myJsonClon from "@/utils/myJsonClone";
export default {
  data () {
    return {
      // -----------------动态表格模式start-----------------
      config: {
        configId: null,
        configTableId: 22,
        configDefaultColumn: [
          {label: '提货时间',prop: 'deliveryTimeNode',fixed: 'left',width: '105',check: true},
          {label: '客户名称',prop: 'orderInfo.customerName',fixed: 'left',width: '70',check: true},
          {label: '进仓单号',prop: 'orderInfo.inWarehouseNo',fixed: 'left',width: '80',check: true},
          {label: '司机车牌',prop: 'vehiclePlan.vehiclePlate',fixed: 'left',width: '70',check: true},
          {label: '司机姓名',prop: 'vehiclePlan.driverName',fixed: 'left',width: '60',check: true},
          {label: '订单类型',prop: 'orderTypeNode',width: '60',formatter: 'orderType',check: true,fixed: undefined},
          {label: '订单车型',prop: 'vehicleTypeNode',width: '80',formatter: 'dictConvertVehicle',check: true,fixed: undefined},
          {label: '提货地址',prop: 'route',width: '80',check: true,fixed: undefined},
          {label: '报关方式',prop: 'entranceTypeNode',width: '80',formatter: 'entranceType',check: true,fixed: undefined},
          {label: '收货地址',prop: 'giveAddressInfo.route',width: '80',check: true,fixed: undefined},
          {label: '应收运费',prop: 'officeCostSale.freightFee',width: '80',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '加点费',prop: 'officeCostSale.addFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '押夜/超时费',prop: 'officeCostSale.overtimeFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '提成费',prop: 'officeCostSale.saleRoyaltyFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '装卸费',prop: 'officeCostSale.loadFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '加班费',prop: 'officeCostSale.extraFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '住宿费',prop: 'officeCostSale.stayFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '基本工资',prop: 'officeCostSale.saleBaseFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '绩效奖罚金',prop: 'officeCostSale.meritsFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: 'APP奖罚金',prop: 'officeCostSale.appFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '租房费',prop: 'officeCostSale.other1Fee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '油耗奖励',prop: 'officeCostSale.other2Fee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '安全奖罚金',prop: 'officeCostSale.safetyRewards',width: '70',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '费用小计',prop: 'officeCostSale.payableAmount',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '已付小计',prop: 'officeCostSale.paidAmount',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '未付小计',prop: 'officeCostSale.notPayAmount',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '扣社保/公积金',prop: 'officeCostSale.deductionsFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '请假扣款',prop: 'officeCostSale.accumulationFund',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '扣减安全奖',prop: 'officeCostSale.safetyAward',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '扣减违章/油耗',prop: 'officeCostSale.oilConsumption',width: '90',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '分摊水电费',prop: 'officeCostSale.hydropower',width: '80',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '个税',prop: 'officeCostSale.personalIncomeTax',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '其他费用(扣)',prop: 'officeCostSale.otherExpenses',width: '90',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '实发小计',prop: 'officeCostSale.netSalary',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '借支',prop: 'officeCostSale.borrowing',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '借支/扣款备注',prop: 'officeCostSale.remark',width: '100',formatter: 'getNumValue',check: true,fixed: undefined},
          {label: '对接人',prop: 'officeCostSale.saleName',width: '70',check: true,fixed: undefined},
          {label: '订单状态',prop: 'status',width: '70',check: true,fixed: undefined},
          {label: '件数',prop: 'orderGoods.pieceNum',width: '60',check: true,fixed: undefined},
          {label: '体积',prop: 'orderGoods.volume',width: '40',check: true,fixed: undefined},
          {label: '重量',prop: 'orderGoods.weight',width: '40',check: true,fixed: undefined},
          {label: '下单时间',prop: 'orderInfo.commitTime',width: '120',check: true,fixed: undefined},
          {label: '下单人',prop: 'orderInfo.commitName',width: '80',check: true,fixed: undefined},
          {label: '付款人',prop: 'officeCostSale.writeName',width: '50',check: true,fixed: undefined},
          {label: '付款时间',prop: 'officeCostSale.writeDate',width: '70',check: true,fixed: undefined},
          {label: '审核状态',prop: '审核状态',width: '60"',check: true,fixed: 'right'},
          {label: '付款状态',prop: '付款状态',width: '60"',check: true,fixed: 'right'},
        ],
        configDefaultPropColumn: [],
        isConfigMode: false,
        configModeForm: {
          name: '',
          remark: '',
          defaultOrNot: '0'
        },
        configDefaultPropColumnMap: new Map(),
      },
      // -----------------动态表格模式end-----------------

      dataForm: {
        takeGoodsTime: null,
        planNo: null,
        orderNo: null,
        status: null,
        inWarehouseNo: null,
        customerName: null,
        driverName: null,
        writeStatus: null,
        vehiclePlate: null,
        saleDepIdList: [], // 业务部
        saleIdList: [], // 业务员
        customerIdList: []
      },
      tableHeight: '71vh',
      listType: 7,
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      pageSizes: [20, 50, 100],
      dataListLoading: false,
      requestVisible: false,
      dataListSelections: [],
      costImageList: [7001, 7002, 7003, 7004, 7005],
      addOrUpdateVisible: false,
      detailVisible: false,
      selectFeeData: [],
      userJobMap: new Map()
    }
  },
  components: {
    configTable,
    costModification,
    viewImage,
    writeOffPopup,
    PayRequest
  },
  created () {
    this.initConfigTable()

    // 判断权限是否查看所有
    if (!this.newAuth('business:department:findAll')) {
      this.dataForm.customerIdList = this.currentUser.customerIdList
    }
    this.setDefaultDate()
    this.getDataList()
    // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
    window.setTimeout(() => {
      this.tableHeight = '72vh'
    }, 1000)
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs['tableRef'].doLayout()
    })
  },
  methods: {
    // -----------------动态表格模式start-----------------
    initConfigTable() {
      this.$http({
        url: this.$http.adornUrl('/tabulation/default/' + this.config.configTableId),
        method: 'get',
        params: this.$http.adornParams()
      }).then(async ({ data }) => {
        if (data) {
          this.config.configModeForm.name = data.name
          this.config.configModeForm.remark = data.remark
          this.config.configModeForm.defaultOrNot = data.defaultOrNot
          this.config.configId = data.id
          if (data.tmsTabulationConfigValueList && data.tmsTabulationConfigValueList.length > 0) {
            this.assignPropertiesLarge(this.config.configDefaultColumn, data.tmsTabulationConfigValueList,'prop',['label','fixed','width'])
            this.config.configDefaultColumn = this.reorderArray(this.config.configDefaultColumn, data.tmsTabulationConfigValueList, 'prop');
          }
        }
        this.config.configDefaultColumn.forEach(x => {
          this.config.configDefaultPropColumn.push({prop: x.prop})
          this.config.configDefaultPropColumnMap.set(x.prop,x)
        })
      })

    },
    assignPropertiesLarge (arr1, arr2, compareProp, assignProps) {
      const map = new Map();
      arr2.forEach(item => {
        map.set(item[compareProp], item);
      });
      arr1.forEach(item => {
        const match = map.get(item[compareProp]);
        if (match) {
          assignProps.forEach(prop => {
            item[prop] = match[prop];
          });
          item.check = true
        } else {
          item.check = false
        }
      });
      return arr1;
    },
    reorderArray(arr1, arr2, key) {
      if (arr2.length === 0) {
        return arr1;
      }

      // 存储第二个数组中元素的特定属性值
      const targetValues = arr2.map(item => item[key]);
      // 存储匹配的元素
      const matched = [];
      // 存储未匹配的元素
      const unmatched = [];

      // 遍历第一个数组，将匹配的元素和未匹配的元素分别存储
      for (const item of arr1) {
        if (targetValues.includes(item[key])) {
          matched.push(item);
        } else {
          unmatched.push(item);
        }
      }

      // 对匹配的元素进行排序，使其顺序与第二个数组一致
      const sortedMatched = targetValues.map(value => {
        return matched.find(item => item[key] == value);
      }).filter(item => item!== undefined);
      // 合并匹配的元素和未匹配的元素
      return [...sortedMatched, ...unmatched];
    },
    templateConfigurationMode(type) {
      // 开启配置模式
      if (type === 0) {
        this.config.isConfigMode = true
        this.$nextTick(() => {
          const tree = this.$refs.configTableListTree;
          this.config.configDefaultColumn.forEach((node) => {
            if (node.check) {
              tree.setChecked(node.prop, true);
            }
          });
        })
      }
      // 保存/修改配置模式
      else if (type === 1 || type === 2) {
        // 检测
        if (!this.config.configModeForm.name) {
          this.$message.error('请输入配置模板名称!');
          return
        }
        if (type === 2) {

          if (!this.config.configId) {
            this.$message.error('未检测出有配置相关模板并启用,请新增!');
            return
          }
        }
        this.$nextTick(() => {
          let submitTable = myJsonClon.getClone(this.config.configModeForm)
          submitTable.tableId = this.config.configTableId
          submitTable.id = type === 2 ? this.config.configId : undefined
          const tableRef = this.$refs.tableRef;
          let tmsTabulationConfigValueList = []
          tableRef.columns.forEach((x,index) => {
            tmsTabulationConfigValueList.push({label: x.label,prop: x.property,fixed: x.fixed,width: x.width,sort: index})
          })
          submitTable.tmsTabulationConfigValueList = tmsTabulationConfigValueList;
          this.$http({
            url: this.$http.adornUrl(type === 1 ? '/tabulation/add' : '/tabulation/update'),
            method: type === 1 ? 'post' : 'put',
            data: submitTable
          }).then(async ({ data }) => {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
            })
            window.location.reload()
          })

        })
      }
    },
    getFormatter(formatterName) {
      if (formatterName) {
        // 首先尝试从组件的 methods 中获取方法
        let method = this[formatterName];
        if (typeof method === 'function') {
          return method;
        }
        // 若组件的 methods 中没有，再尝试从 Vue.prototype 中获取方法
        method = Vue.prototype[formatterName];
        if (typeof method === 'function') {
          return method;
        }
      }
      // 若都没找到，返回一个直接返回原始值的函数
      return (row, column, cellValue) => cellValue;
    },
    checkAllNodes (isChecked) {
      this.$nextTick(() => {
        if (isChecked) {
          this.$refs.configTableListTree.setCheckedNodes(this.config.configDefaultPropColumn)
        } else {
          this.$refs.configTableListTree.setCheckedKeys([])
          this.$forceUpdate()
        }
      })
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      const tree = this.$refs.configTableListTree;
      tree.setChecked(draggingNode.data.prop, draggingNode.data.check);
    },
    handleCheckChange(data, checked, indeterminate) {
      const targetElement = this.config.configDefaultColumn.find(item => item.prop === data.prop);
      targetElement.check = checked
    },
    allowDrag(draggingNode) {
      return draggingNode.data.fixed ? false : true
    },
    allowDrop(draggingNode, dropNode, type) {
      return type === 'inner' ? false : true
    },
    getCellClassName({ row, column, rowIndex, columnIndex }) {
      let newVar = this.config.configDefaultPropColumnMap.get(column.property);
      if (newVar && newVar.styleClass) {
        return newVar.styleClass;
      }
      return '';
    },
    // -----------------动态表格模式end-----------------

    // 获取对接人信息
    getUserList () {
      this.userJobMap = new Map()
      this.$http({
        url: this.$http.adornUrl(`/costInfo/findAllAByJobCodes`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.userJobMap = data
      })
    },
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100) !important'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    examine () {

    },
    handleSelectionChange (row) {
      this.selectFeeData = row
    },
    // 查看照片
    viewPhoto (row) {
      this.$refs.viewPhoto.init(row.officeCostSale.id, this.costImageList)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (item.orderInfo.id === preId) {
            return ''
          }
          preId = item.orderInfo.id
          if (column.property && column.property.indexOf('officeCostSale.') > -1) {
            let val = column.property.split('.')
            return this.getValue(Number(item[val[0]][val[1]]))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 截取当前数据到小数点后两位
    numFilter (value) {
      if (value === '') {
        return value
      }
      return parseFloat(parseFloat(value).toFixed(2))
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (officeCostSale) {
      for (let c in officeCostSale) {
        officeCostSale[c] = this.getValue(officeCostSale[c])
      }
      // 合计参数，按顺序返回
      let param = []
      param.push('合计')
      this.config.configDefaultPropColumn.forEach(x => {
        let value = _.get({ officeCostSale }, x.prop);
        param.push(value == undefined ? '' : value)
      })
      // 创建列表行
      let tr = document.createElement('tr')
      // var itemTr = document.createElement('tr')
      // itemTr.innerHTML = document.querySelector('.el-table__footer-wrapper table tbody tr').innerHTML
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    createPayRequest () {
      let ids = []
      let motorcadeIdSet = new Set()
      let driverIdSet = new Set()
      for (let i = 0; i < this.selectFeeData.length; i++) {
        let item = this.selectFeeData[i]
        if (item.officeCostSale.writeStatus !== 1 && item.officeCostSale.status === 1) {
          // 校验是否同一个车队
          ids.push(item.officeCostSale.id)
          motorcadeIdSet.add(item.driverInfo.motorcadeId)
          driverIdSet.add(item.driverInfo.id)
        }
      }
      if (ids.length === 0) {
        this.$message.error('没有符合生成的数据，请检查是否勾选正确')
        return
      }
      // 校验是否同一个司机
      if (driverIdSet.size > 1) {
        this.$message.error('已选的订单存在多个付款司机对象，无法生成付款申请单')
        return
      }
      let costData = {
        ids: ids,
        motorcadeId: this.getSetFirst(motorcadeIdSet),
        payObject: 5, // 业务经理
        payBasisSource: 1,
        payOrderType: 3,
        disableDriver: true,
        driverId: this.getSetFirst(driverIdSet)
      }
      // 确认对话框
      this.$confirm('请确认是否生成付款申请单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.openPayRequest(costData)
      }).catch(() => {
      })
    },
    getSetFirst (set) {
      let arr = [...set]
      return arr[0]
    },
    openPayRequest (costData) {
      this.requestVisible = true
      this.$nextTick(() => {
        this.$refs.payRequest.payGenInit(costData)
      })
    },
    operatopnList (type, todo, row, status) {
      if (type === 'pass') {
        // this.$refs.pass.init()
        let ids = []
        if (row) {
          ids.push(row.officeCostSale.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.officeCostSale.id)
          })
        }
        this.$confirm(status === 1 ? '正在执行【费用审核通过】操作，是否继续?' : '正在执行【反审核】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/officeCost/officeSaleAdminRoyalty/audit'),
            method: 'post',
            data: {
              ids: ids,
              status: status
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => {})
      } else if (type === 'cost') {
        this.$refs.cost.init(type, todo, row)
      }
    },
    batchAudit (writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.officeCostSale.writeStatus !== writeStatus) {
          ids.push(item.officeCostSale.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/officeCost/saleAdminOrderRoyalty/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    openWriteOffPopup (writeStatus) {
      this.writeOffVisible = true
      let ids = []
      this.selectFeeData.forEach(item => {
        // 已付款和申请中的不可核销
        if (item.officeCostSale.writeStatus !== writeStatus && item.officeCostSale.writeStatus !== 4) {
          ids.push(item.officeCostSale.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      this.$refs.writeOff.init(writeStatus, ids, 'saleAdmin')
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/export/saleAdminExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'listType': this.listType,
          'page': this.pageIndex,
          'limit': this.pageSize,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'status': this.dataForm.status,
          'writeStatus': this.dataForm.writeStatus,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'saleId': this.dataForm.saleIdList // 传入业务对接人
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '业务部提成费用表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 计算最新数据：基本工资、绩效奖励、使用APP奖励
    getLatestData () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/officeCost/getLatestDataSaleAdmin`),
        method: 'POST',
        data: this.$http.adornData({
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'saleId': this.dataForm.saleIdList
        })
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.getDataList()
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.getUserList()
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'status': this.dataForm.status,
        'writeStatus': this.dataForm.writeStatus,
        'inWarehouseNo': this.dataForm.inWarehouseNo,
        'saleId': this.dataForm.saleIdList, // 传入业务对接人
        'driverName': this.dataForm.driverName,
        'vehiclePlate': this.dataForm.vehiclePlate,
        'customerName': this.dataForm.customerName,
        'customerIdList': this.dataForm.customerIdList
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/officeCost/findOfficeCostSaleAdminList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          const columns = this.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          this.getSpanArr(data.list, columns)
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.officeSaleRoyalty || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (row, todo) {
      this.$nextTick(() => {
        this.$refs.detail.init(row, todo)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.simpleName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/company/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.my-table-cell) {
  background-color: antiquewhite!important;
}
:deep(.my-table-cell-v2) {
  background-color: beige!important;
}
:deep(.my-table-cell-v3) {
  background-color: aliceblue!important;
}
</style>
